import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { storage, db } from '../../lib/firebase';
import { optimizeVideo } from '../video';
import toast from 'react-hot-toast';

interface UploadStoryParams {
  file?: File;
  type: 'image' | 'video' | 'spotify' | 'youtube';
  userId: string;
  userName: string;
  userPhotoURL: string | null;
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
  youtubeVideo?: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  };
  onProgress?: (progress: number) => void;
}

export async function uploadStory({
  file,
  type,
  userId,
  userName,
  userPhotoURL,
  spotifyTrack,
  youtubeVideo,
  onProgress
}: UploadStoryParams) {
  let mediaUrl = '';
  let tempStorageRef: any = null;

  try {
    if (type !== 'spotify' && type !== 'youtube') {
      if (!file) throw new Error('File is required for image/video stories');

      let processedFile = file;
      if (type === 'video') {
        try {
          // Skip optimization for iOS devices
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
          if (!isIOS) {
            processedFile = await optimizeVideo(file);
          }
          onProgress?.(30);
        } catch (error) {
          console.error('Video optimization failed:', error);
          // Continue with original file if optimization fails
          onProgress?.(30);
        }
      }

      // Generate storage path with date structure
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      
      // Generate unique filename
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(7);
      const extension = type === 'image' ? 'jpg' : 'webm';
      const filename = `${userId}_${timestamp}_${randomString}.${extension}`;
      
      // Create full storage path
      const storagePath = `stories/${year}/${month}/${day}/${filename}`;

      // Upload to storage
      tempStorageRef = ref(storage, storagePath);
      
      // For iOS videos, ensure proper content type
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isVideo = type === 'video';
      
      if (isIOS && isVideo) {
        // Determine content type based on file extension
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        let contentType = file.type;
        
        if (!contentType || contentType === 'application/octet-stream') {
          if (fileExtension === 'mov') {
            contentType = 'video/quicktime';
          } else if (fileExtension === 'mp4') {
            contentType = 'video/mp4';
          } else {
            contentType = 'video/mp4'; // Default fallback
          }
        }
        
        await uploadBytes(tempStorageRef, processedFile, {
          contentType,
          customMetadata: {
            isIOS: 'true',
            originalName: file.name
          }
        });
      } else {
        await uploadBytes(tempStorageRef, processedFile);
      }
      
      onProgress?.(80);
      mediaUrl = await getDownloadURL(tempStorageRef);
      onProgress?.(90);
    }

    // Create story document
    const storyData = {
      mediaUrl,
      mediaType: type,
      authorId: userId,
      authorName: userName,
      authorUsername: userName.startsWith('@') ? userName.slice(1) : userName,
      authorPhotoURL: userPhotoURL,
      timestamp: serverTimestamp(),
      likes: [],
      views: [],
      ...(type === 'spotify' && { spotifyTrack }),
      ...(type === 'youtube' && { youtubeVideo })
    };

    const docRef = await addDoc(collection(db, 'stories'), storyData);
    onProgress?.(100);

    return {
      id: docRef.id,
      ...storyData,
      timestamp: new Date()
    };
  } catch (error: any) {
    console.error('Error uploading story:', error);
    throw error;
  }
}