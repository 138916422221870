import { useState, useEffect } from 'react';
import { Loader2, Search, Filter, Download, Calendar, User, Activity } from 'lucide-react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { ActionType } from '../../utils/logging';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import toast from 'react-hot-toast';

interface ActivityLogsProps {
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Logs de Atividade',
    loading: 'Carregando logs...',
    noLogs: 'Nenhum log de atividade encontrado',
    searchPlaceholder: 'Buscar por usuário, email ou IP...',
    filterByAction: 'Filtrar por ação',
    downloadLogs: 'Baixar Logs',
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    columns: {
      timestamp: 'Data/Hora',
      user: 'Usuário',
      email: 'Email',
      ip: 'Endereço IP',
      action: 'Ação',
      details: 'Detalhes'
    },
    actions: {
      login: 'Login',
      signup: 'Cadastro',
      logout: 'Logout',
      create_post: 'Criação de Post',
      delete_post: 'Exclusão de Post',
      like_post: 'Curtida em Post',
      unlike_post: 'Descurtida em Post',
      comment_post: 'Comentário em Post',
      create_story: 'Criação de Story',
      view_story: 'Visualização de Story',
      follow_user: 'Seguir Usuário',
      unfollow_user: 'Deixar de Seguir',
      block_user: 'Bloquear Usuário',
      unblock_user: 'Desbloquear Usuário',
      update_profile: 'Atualização de Perfil',
      search_user: 'Busca de Usuário',
      view_profile: 'Visualização de Perfil',
      send_message: 'Envio de Mensagem',
      join_community: 'Entrar em Comunidade',
      leave_community: 'Sair de Comunidade',
      create_community: 'Criar Comunidade',
      create_topic: 'Criar Tópico',
      boost_post: 'Impulsionar Post',
      report_content: 'Denúncia de Conteúdo',
      admin_action: 'Ação de Admin'
    }
  },
  'en': {
    title: 'Activity Logs',
    loading: 'Loading logs...',
    noLogs: 'No activity logs found',
    searchPlaceholder: 'Search by user, email or IP...',
    filterByAction: 'Filter by action',
    downloadLogs: 'Download Logs',
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    columns: {
      timestamp: 'Timestamp',
      user: 'User',
      email: 'Email',
      ip: 'IP Address',
      action: 'Action',
      details: 'Details'
    },
    actions: {
      login: 'Login',
      signup: 'Signup',
      logout: 'Logout',
      create_post: 'Create Post',
      delete_post: 'Delete Post',
      like_post: 'Like Post',
      unlike_post: 'Unlike Post',
      comment_post: 'Comment Post',
      create_story: 'Create Story',
      view_story: 'View Story',
      follow_user: 'Follow User',
      unfollow_user: 'Unfollow User',
      block_user: 'Block User',
      unblock_user: 'Unblock User',
      update_profile: 'Update Profile',
      search_user: 'Search User',
      view_profile: 'View Profile',
      send_message: 'Send Message',
      join_community: 'Join Community',
      leave_community: 'Leave Community',
      create_community: 'Create Community',
      create_topic: 'Create Topic',
      boost_post: 'Boost Post',
      report_content: 'Report Content',
      admin_action: 'Admin Action'
    }
  }
};

const LOGS_LIMIT = 50;

export function ActivityLogs({ isDarkMode = false, language = 'en' }: ActivityLogsProps) {
  const [logs, setLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [actionFilter, setActionFilter] = useState<ActionType | ''>('');
  const [filteredLogs, setFilteredLogs] = useState<any[]>([]);
  const [unsubscribe, setUnsubscribe] = useState<(() => void) | null>(null);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    // Set up real-time listener for logs
    const logsRef = collection(db, 'activity_logs');
    const q = query(
      logsRef,
      orderBy('timestamp', 'desc'),
      limit(LOGS_LIMIT)
    );

    const unsubscribeListener = onSnapshot(q, 
      (snapshot) => {
        const logData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        }));
        
        setLogs(logData);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1] || null);
        setHasMore(snapshot.docs.length === LOGS_LIMIT);
        setLoading(false);
      },
      (error) => {
        console.error('Error listening to logs:', error);
        toast.error('Failed to load activity logs');
        setLoading(false);
      }
    );

    setUnsubscribe(() => unsubscribeListener);

    // Clean up listener on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    // Filter logs based on search query and action filter
    let filtered = logs;
    
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(log => 
        log.username?.toLowerCase().includes(query) ||
        log.email?.toLowerCase().includes(query) ||
        log.ipAddress?.toLowerCase().includes(query)
      );
    }
    
    if (actionFilter) {
      filtered = filtered.filter(log => log.actionType === actionFilter);
    }
    
    setFilteredLogs(filtered);
  }, [logs, searchQuery, actionFilter]);

  const loadMoreLogs = async () => {
    if (!lastDoc || loadingMore) return;

    try {
      setLoadingMore(true);
      
      // Unsubscribe from current listener
      if (unsubscribe) {
        unsubscribe();
      }
      
      // Create a new query starting after the last document
      const logsRef = collection(db, 'activity_logs');
      const q = query(
        logsRef,
        orderBy('timestamp', 'desc'),
        limit(LOGS_LIMIT * 2) // Double the limit to include previous logs plus new ones
      );
      
      // Set up new listener
      const unsubscribeListener = onSnapshot(q, 
        (snapshot) => {
          const logData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate() || new Date()
          }));
          
          setLogs(logData);
          setLastDoc(snapshot.docs[snapshot.docs.length - 1] || null);
          setHasMore(snapshot.docs.length === LOGS_LIMIT * 2);
          setLoadingMore(false);
        },
        (error) => {
          console.error('Error listening to more logs:', error);
          toast.error('Failed to load more logs');
          setLoadingMore(false);
        }
      );
      
      setUnsubscribe(() => unsubscribeListener);
    } catch (error) {
      console.error('Error loading more logs:', error);
      toast.error('Failed to load more logs');
      setLoadingMore(false);
    }
  };

  const downloadLogs = () => {
    try {
      const jsonString = JSON.stringify(logs, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = url;
      a.download = `activity_logs_${new Date().toISOString()}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
      toast.success('Logs downloaded successfully');
    } catch (error) {
      console.error('Error downloading logs:', error);
      toast.error('Failed to download logs');
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {t('title')}
        </h2>
        <button
          onClick={downloadLogs}
          className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
            isDarkMode 
              ? 'bg-blue-600 text-white hover:bg-blue-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          <Download className="h-4 w-4" />
          <span>{t('downloadLogs')}</span>
        </button>
      </div>

      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
        <div className="relative flex-1">
          <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('searchPlaceholder')}
            className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
            }`}
          />
        </div>
        
        <div className="relative">
          <Filter className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <select
            value={actionFilter}
            onChange={(e) => setActionFilter(e.target.value as ActionType | '')}
            className={`pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300 text-gray-900'
            }`}
          >
            <option value="">{t('filterByAction')}</option>
            {Object.values(ActionType).map((action) => (
              <option key={action} value={action}>
                {t(`actions.${action}`)}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-8">
          <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
        </div>
      ) : filteredLogs.length > 0 ? (
        <div className={`overflow-x-auto ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow`}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <tr>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'}`}>
                  {t('columns.timestamp')}
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'}`}>
                  {t('columns.user')}
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'}`}>
                  {t('columns.email')}
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'}`}>
                  {t('columns.ip')}
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'}`}>
                  {t('columns.action')}
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${isDarkMode ? 'text-gray-300 uppercase tracking-wider' : 'text-gray-500 uppercase tracking-wider'}`}>
                  {t('columns.details')}
                </th>
              </tr>
            </thead>
            <tbody className={`${isDarkMode ? 'bg-gray-800 divide-y divide-gray-700' : 'bg-white divide-y divide-gray-200'}`}>
              {filteredLogs.map((log) => (
                <tr key={log.id} className={isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                    {formatDistanceToNow(log.timestamp, { 
                      addSuffix: true,
                      locale: language === 'pt-BR' ? ptBR : undefined
                    })}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    @{log.username}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                    {log.email}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                    {log.ipAddress}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {t(`actions.${log.actionType}`)}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                    {log.details ? JSON.stringify(log.details) : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center py-8">
          <Activity className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
          <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
            {t('noLogs')}
          </p>
        </div>
      )}

      {hasMore && (
        <div className="flex justify-center mt-6">
          <button
            onClick={loadMoreLogs}
            disabled={loadingMore}
            className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-black text-white hover:bg-gray-800'
            }`}
          >
            {loadingMore ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>{t('loadingMore')}</span>
              </>
            ) : (
              <span>{t('showMore')}</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}