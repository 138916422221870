import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { getUserIP } from './ip';

export enum ActionType {
  LOGIN = 'login',
  SIGNUP = 'signup',
  LOGOUT = 'logout',
  CREATE_POST = 'create_post',
  DELETE_POST = 'delete_post',
  LIKE_POST = 'like_post',
  UNLIKE_POST = 'unlike_post',
  COMMENT_POST = 'comment_post',
  CREATE_STORY = 'create_story',
  VIEW_STORY = 'view_story',
  FOLLOW_USER = 'follow_user',
  UNFOLLOW_USER = 'unfollow_user',
  BLOCK_USER = 'block_user',
  UNBLOCK_USER = 'unblock_user',
  UPDATE_PROFILE = 'update_profile',
  SEARCH_USER = 'search_user',
  VIEW_PROFILE = 'view_profile',
  SEND_MESSAGE = 'send_message',
  JOIN_COMMUNITY = 'join_community',
  LEAVE_COMMUNITY = 'leave_community',
  CREATE_COMMUNITY = 'create_community',
  CREATE_TOPIC = 'create_topic',
  BOOST_POST = 'boost_post',
  REPORT_CONTENT = 'report_content',
  ADMIN_ACTION = 'admin_action'
}

export interface LogEntry {
  userId: string;
  username: string;
  email: string;
  ipAddress: string;
  userAgent: string;
  actionType: ActionType;
  timestamp: any; // FirebaseFirestore.FieldValue
  details?: Record<string, any> | null;
}

/**
 * Logs a user action to Firebase
 * @param userId User ID
 * @param username Username
 * @param email User email
 * @param actionType Type of action performed
 * @param details Additional details about the action
 */
export async function logUserAction(
  userId: string,
  username: string,
  email: string,
  actionType: ActionType,
  details?: Record<string, any>
) {
  try {
    const ipAddress = await getUserIP();
    const userAgent = navigator.userAgent;

    // Clean up details object to prevent undefined values
    const cleanDetails = details ? Object.fromEntries(
      Object.entries(details).filter(([_, value]) => value !== undefined)
    ) : null;

    const logEntry: LogEntry = {
      userId,
      username,
      email,
      ipAddress,
      userAgent,
      actionType,
      timestamp: serverTimestamp(),
      details: Object.keys(cleanDetails || {}).length > 0 ? cleanDetails : null
    };

    await addDoc(collection(db, 'activity_logs'), logEntry);
    
    // Also log to console in development
    if (import.meta.env.DEV) {
      console.log('Activity logged:', actionType, {
        userId,
        username,
        email,
        ipAddress,
        details: cleanDetails
      });
    }
  } catch (error) {
    console.error('Error logging user action:', error);
    // Don't throw - logging should never break the application flow
  }
}