import { User, deleteUser } from 'firebase/auth';
import { doc, deleteDoc, collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { ref, deleteObject, listAll } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { logUserAction, ActionType } from './logging';
import toast from 'react-hot-toast';

export async function deleteUserAccount(user: User) {
  try {
    // Log the account deletion action
    await logUserAction(
      user.uid,
      user.displayName?.replace('@', '') || '',
      user.email || '',
      ActionType.ADMIN_ACTION,
      {
        action: 'delete_account',
        timestamp: new Date().toISOString()
      }
    );
    
    // Start with a batch write for Firestore operations
    const batch = writeBatch(db);

    // 1. Delete user's posts and their associated data
    const postsRef = collection(db, 'posts');
    const postsQuery = query(postsRef, where('authorId', '==', user.uid));
    const postsSnapshot = await getDocs(postsQuery);
    
    for (const postDoc of postsSnapshot.docs) {
      // Delete post media
      try {
        const mediaRef = ref(storage, `posts/${user.uid}/${postDoc.id}`);
        const mediaFiles = await listAll(mediaRef);
        await Promise.all(mediaFiles.items.map(file => deleteObject(file)));
      } catch (error) {
        console.error('Error deleting post media:', error);
      }

      // Delete post comments
      const commentsRef = collection(db, 'posts', postDoc.id, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      commentsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Delete the post document
      batch.delete(postDoc.ref);
    }

    // 2. Delete user's stories
    const storiesRef = collection(db, 'stories');
    const storiesQuery = query(storiesRef, where('authorId', '==', user.uid));
    const storiesSnapshot = await getDocs(storiesQuery);
    
    for (const storyDoc of storiesSnapshot.docs) {
      try {
        // Delete story media
        const storyRef = ref(storage, `stories/${storyDoc.id}`);
        await deleteObject(storyRef);
      } catch (error) {
        console.error('Error deleting story media:', error);
      }
      batch.delete(storyDoc.ref);
    }

    // 3. Delete user's profile photo
    try {
      const avatarRef = ref(storage, `avatars/${user.uid}`);
      await deleteObject(avatarRef);
    } catch (error) {
      console.error('Error deleting avatar:', error);
    }

    // 4. Delete user's notifications
    const notificationsRef = collection(db, 'notifications');
    const sentNotificationsQuery = query(notificationsRef, where('senderId', '==', user.uid));
    const receivedNotificationsQuery = query(notificationsRef, where('recipientId', '==', user.uid));

    const [sentSnapshot, receivedSnapshot] = await Promise.all([
      getDocs(sentNotificationsQuery),
      getDocs(receivedNotificationsQuery)
    ]);

    sentSnapshot.docs.forEach(doc => batch.delete(doc.ref));
    receivedSnapshot.docs.forEach(doc => batch.delete(doc.ref));

    // 5. Delete user's chat messages and chats
    const chatsRef = collection(db, 'chats');
    const chatsQuery = query(chatsRef, where('participants', 'array-contains', user.uid));
    const chatsSnapshot = await getDocs(chatsQuery);

    for (const chatDoc of chatsSnapshot.docs) {
      const messagesRef = collection(db, 'chats', chatDoc.id, 'messages');
      const messagesSnapshot = await getDocs(messagesRef);
      messagesSnapshot.docs.forEach(doc => batch.delete(doc.ref));
      batch.delete(chatDoc.ref);
    }

    // 6. Delete user's community data
    const communitiesRef = collection(db, 'communities');
    const communitiesQuery = query(communitiesRef, where('participants', 'array-contains', user.uid));
    const communitiesSnapshot = await getDocs(communitiesQuery);

    for (const communityDoc of communitiesSnapshot.docs) {
      // Remove user from participants array instead of deleting the community
      const participants = communityDoc.data().participants.filter((id: string) => id !== user.uid);
      batch.update(communityDoc.ref, { participants });

      // Delete user's topics and responses
      const topicsRef = collection(db, 'communities', communityDoc.id, 'topics');
      const topicsQuery = query(topicsRef, where('authorId', '==', user.uid));
      const topicsSnapshot = await getDocs(topicsQuery);

      for (const topicDoc of topicsSnapshot.docs) {
        const responsesRef = collection(topicsRef, topicDoc.id, 'responses');
        const responsesSnapshot = await getDocs(responsesRef);
        responsesSnapshot.docs.forEach(doc => batch.delete(doc.ref));
        batch.delete(topicDoc.ref);
      }
    }

    // 7. Delete user's activity logs
    const logsRef = collection(db, 'activity_logs');
    const logsQuery = query(logsRef, where('userId', '==', user.uid));
    const logsSnapshot = await getDocs(logsQuery);
    logsSnapshot.docs.forEach(doc => batch.delete(doc.ref));

    // 8. Delete user document
    batch.delete(doc(db, 'users', user.uid));

    // Commit all Firestore deletions
    await batch.commit();

    // 9. Finally, delete the authentication account
    await deleteUser(user);

    toast.success('Your account has been deleted');
  } catch (error: any) {
    console.error('Error deleting account:', error);
    throw new Error(error.message || 'Failed to delete account');
  }
}