import { MAX_VIDEO_DURATION } from '../video/constants';

export function validateStoryFile(file: File, type: 'image' | 'video'): { valid: boolean; error?: string } {
  // Validate file size (150MB max)
  const MAX_FILE_SIZE = 150 * 1024 * 1024; // 150MB
  if (file.size > MAX_FILE_SIZE) {
    return { valid: false, error: 'File size must be less than 150MB' };
  }

  // Explicitly reject GIF files
  if (file.type === 'image/gif') {
    return { 
      valid: false, 
      error: 'GIF files are not allowed. Please upload a JPEG, PNG, or WebP image.' 
    };
  }

  // Validate file type
  if (type === 'image' && !['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
    return { valid: false, error: 'Please select a valid image file (JPEG, PNG, or WebP)' };
  }

  if (type === 'video') {
    // Accept all video/* MIME types
    if (!file.type.startsWith('video/')) {
      // For iOS, check file extension as fallback since MIME types can be unreliable
      const extension = file.name.split('.').pop()?.toLowerCase();
      if (extension !== 'mp4' && extension !== 'mov' && extension !== 'quicktime') {
        return { valid: false, error: 'Please select a video file (MP4 or MOV)' };
      }
    }
  }

  return { valid: true };
}

export async function validateVideoDuration(file: File): Promise<{ valid: boolean; error?: string }> {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    
    // Important for iOS
    video.playsInline = true;
    
    // Set up timeout in case metadata loading hangs
    const timeoutId = setTimeout(() => {
      URL.revokeObjectURL(video.src);
      resolve({ valid: true }); // Assume valid on timeout for iOS
    }, 5000);

    video.onloadedmetadata = () => {
      clearTimeout(timeoutId);
      URL.revokeObjectURL(video.src);
      
      if (video.duration > MAX_VIDEO_DURATION) {
        // Check user's language for error message
        const userLang = navigator.language;
        const errorMessage = userLang.startsWith('pt') 
          ? `O vídeo deve ter no máximo ${MAX_VIDEO_DURATION} segundos`
          : `Video must be ${MAX_VIDEO_DURATION} seconds or less`;
          
        resolve({ valid: false, error: errorMessage });
      } else {
        resolve({ valid: true });
      }
    };

    video.onerror = () => {
      clearTimeout(timeoutId);
      URL.revokeObjectURL(video.src);
      
      // For iOS, we'll be more permissive with errors
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        console.warn('iOS video validation error - assuming valid');
        resolve({ valid: true });
      } else {
        // Check user's language for error message
        const userLang = navigator.language;
        const errorMessage = userLang.startsWith('pt') 
          ? 'Falha ao validar vídeo'
          : 'Failed to validate video';
          
        resolve({ valid: false, error: errorMessage });
      }
    };

    video.src = URL.createObjectURL(file);
    
    // Force load for iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      video.load();
    }
  });
}