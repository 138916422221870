import { moderateImage } from '../vision';
import toast from 'react-hot-toast';

/**
 * Extracts a frame from a video file at a specific time
 * @param file The video file
 * @param timeInSeconds The time in seconds to extract the frame from
 * @returns A Promise that resolves to a base64-encoded image
 */
export async function extractVideoFrame(file: File, timeInSeconds: number = 1): Promise<string> {
  return new Promise((resolve, reject) => {
    // Create video element
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.playsInline = true; // Important for iOS
    
    // Create canvas for frame extraction
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    if (!ctx) {
      reject(new Error('Could not create canvas context'));
      return;
    }
    
    // Set up video event handlers
    video.onloadedmetadata = () => {
      // Set dimensions
      canvas.width = video.videoWidth || 640;
      canvas.height = video.videoHeight || 480;
      
      // Seek to the specified time
      video.currentTime = Math.min(timeInSeconds, video.duration / 2);
    };
    
    video.onseeked = () => {
      try {
        // Draw the video frame to the canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        // Convert canvas to base64 image
        const base64Image = canvas.toDataURL('image/jpeg', 0.8);
        
        // Clean up
        URL.revokeObjectURL(video.src);
        
        resolve(base64Image);
      } catch (error) {
        console.error('Error extracting frame:', error);
        // For iOS, provide a fallback
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        if (isIOS) {
          // Create a simple colored canvas as fallback
          ctx.fillStyle = '#f0f0f0';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = '#333333';
          ctx.font = '20px Arial';
          ctx.fillText('Video frame', 20, canvas.height / 2);
          
          const fallbackImage = canvas.toDataURL('image/jpeg', 0.8);
          resolve(fallbackImage);
        } else {
          reject(error);
        }
      }
    };
    
    video.onerror = () => {
      URL.revokeObjectURL(video.src);
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        // Create a simple colored canvas as fallback
        canvas.width = 640;
        canvas.height = 480;
        ctx.fillStyle = '#f0f0f0';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#333333';
        ctx.font = '20px Arial';
        ctx.fillText('Video preview', 20, canvas.height / 2);
        
        const fallbackImage = canvas.toDataURL('image/jpeg', 0.8);
        resolve(fallbackImage);
      } else {
        reject(new Error('Error loading video for moderation'));
      }
    };
    
    // Set up timeout for iOS
    const timeoutId = setTimeout(() => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        console.warn('Timeout extracting video frame - using fallback');
        canvas.width = 640;
        canvas.height = 480;
        ctx.fillStyle = '#f0f0f0';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#333333';
        ctx.font = '20px Arial';
        ctx.fillText('Video preview', 20, canvas.height / 2);
        
        const fallbackImage = canvas.toDataURL('image/jpeg', 0.8);
        resolve(fallbackImage);
      }
    }, 5000);
    
    // Load the video
    video.src = URL.createObjectURL(file);
    video.load(); // Important for iOS
    
    // Clean up timeout on success
    video.onseeked = () => {
      clearTimeout(timeoutId);
      try {
        // Draw the video frame to the canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        // Convert canvas to base64 image
        const base64Image = canvas.toDataURL('image/jpeg', 0.8);
        
        // Clean up
        URL.revokeObjectURL(video.src);
        
        resolve(base64Image);
      } catch (error) {
        console.error('Error extracting frame:', error);
        // For iOS, provide a fallback
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        if (isIOS) {
          // Create a simple colored canvas as fallback
          ctx.fillStyle = '#f0f0f0';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = '#333333';
          ctx.font = '20px Arial';
          ctx.fillText('Video frame', 20, canvas.height / 2);
          
          const fallbackImage = canvas.toDataURL('image/jpeg', 0.8);
          resolve(fallbackImage);
        } else {
          reject(error);
        }
      }
    };
  });
}

/**
 * Moderates a video by extracting frames and checking them with Cloud Vision
 * @param file The video file to moderate
 * @returns A Promise that resolves to a boolean indicating if the video is safe
 */
export async function moderateVideo(file: File): Promise<boolean> {
  try {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    // For iOS, use a simplified approach with fewer frames
    if (isIOS) {
      try {
        // Just try to extract one frame from the beginning
        const frame = await extractVideoFrame(file, 1);
        if (!frame) return true; // If we can't extract a frame, assume it's safe
        
        // Check the frame with Cloud Vision
        return await moderateImage(frame);
      } catch (error) {
        console.warn('iOS video moderation error, assuming safe:', error);
        return true; // On iOS, if moderation fails, assume it's safe
      }
    }
    
    // For other platforms, use the full approach with multiple frames
    // Extract frames from different parts of the video
    const framePromises = [
      extractVideoFrame(file, 1),           // Beginning
      extractVideoFrame(file, 5),           // Early
      extractVideoFrame(file, 15),          // Middle-beginning
      extractVideoFrame(file, 30),          // Middle
    ];
    
    // Get frames that exist (some might not if video is shorter)
    const frames = await Promise.all(
      framePromises.map(p => p.catch(() => null))
    );
    
    // Filter out null frames
    const validFrames = frames.filter(frame => frame !== null) as string[];
    
    if (validFrames.length === 0) {
      console.warn('Could not extract frames from video, assuming safe');
      return true; // If we can't extract any frames, assume it's safe
    }
    
    // Check each frame with Cloud Vision
    const moderationResults = await Promise.all(
      validFrames.map(frame => moderateImage(frame))
    );
    
    // If any frame is not safe, the video is not safe
    const isVideoSafe = moderationResults.every(result => result === true);
    
    return isVideoSafe;
  } catch (error) {
    console.error('Error moderating video:', error);
    // Default to requiring manual review on error
    return false;
  }
}