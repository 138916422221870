import { useState, useRef, useEffect } from 'react';
import { Play, Pause, Clock, Loader2 } from 'lucide-react';
import { loadVideoPreview } from '../utils/video/preview';
import { formatDuration } from '../utils/video/duration';

interface VideoPlayerProps {
  url: string;
}

export function VideoPlayer({ url }: VideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [duration, setDuration] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const progressInterval = useRef<number>();

  useEffect(() => {
    if (videoRef.current) {
      loadVideoPreview(videoRef.current, () => setPreviewLoaded(true));
      
      // Get video duration
      videoRef.current.addEventListener('loadedmetadata', () => {
        setDuration(videoRef.current?.duration || null);
      });
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [url]);

  const handlePlayPause = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
      clearInterval(progressInterval.current);
    } else {
      setIsLoading(true);
      videoRef.current.currentTime = 0;
      videoRef.current.play()
        .then(() => {
          setIsPlaying(true);
          setIsLoading(false);
          
          // Update progress
          progressInterval.current = window.setInterval(() => {
            if (videoRef.current) {
              setCurrentTime(videoRef.current.currentTime);
            }
          }, 250);
        })
        .catch((error) => {
          console.error('Error playing video:', error);
          setIsPlaying(false);
          setIsLoading(false);
        });
    }
  };

  const progressPercentage = duration ? (currentTime / duration) * 100 : 0;

  return (
    <div className="relative w-full aspect-square rounded-lg overflow-hidden mb-3 bg-black group">
      <video
        ref={videoRef}
        src={url}
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
          previewLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        playsInline
        muted={false}
        onClick={handlePlayPause}
        onEnded={() => {
          setIsPlaying(false);
          clearInterval(progressInterval.current);
        }}
      />
      
      {!isPlaying && (
        <button
          onClick={handlePlayPause}
          className="absolute inset-0 flex items-center justify-center bg-black/30 hover:bg-black/40 transition-colors"
        >
          {isLoading ? (
            <Loader2 className="h-12 w-12 text-white animate-spin" />
          ) : (
            <Play className="h-12 w-12 text-white" />
          )}
        </button>
      )}

      {duration && (
        <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/60 to-transparent">
          {/* Progress bar */}
          <div className="w-full h-1 bg-gray-600 rounded-full mb-2 overflow-hidden">
            <div 
              className="h-full bg-white transition-all duration-100"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
          
          {/* Duration */}
          <div className="flex justify-between items-center text-white text-xs">
            <span>{formatDuration(currentTime)}</span>
            <span>{formatDuration(duration)}</span>
          </div>
        </div>
      )}
    </div>
  );
}