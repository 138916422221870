import { MAX_VIDEO_SIZE, MAX_VIDEO_DURATION } from './constants';
import { isIPhoneVideo, isHEVCVideo } from './formats';
import { processHEVCVideo } from './hevc-handler';
import { processIOSVideo } from './ios-handler';
import { normalizeVideoMimeType } from './mime-types';

interface ValidationResult {
  valid: boolean;
  error?: string;
  normalizedFile?: File;
}

export function validateVideoFile(file: File): ValidationResult {
  // Validate file size
  if (file.size > MAX_VIDEO_SIZE) {
    return { 
      valid: false, 
      error: `Video file is too large. Maximum size is ${Math.floor(MAX_VIDEO_SIZE / (1024 * 1024))}MB` 
    };
  }

  // Check if file is a video
  if (!file.type.startsWith('video/')) {
    // For iOS, check file extension as fallback
    const extension = file.name.split('.').pop()?.toLowerCase();
    if (extension !== 'mp4' && extension !== 'mov' && extension !== 'quicktime') {
      return { 
        valid: false, 
        error: 'Please select a valid video file (MP4 or MOV)' 
      };
    }
  }

  // Normalize MIME type
  const normalizedMimeType = normalizeVideoMimeType(file);
  
  // Create a normalized file with the correct MIME type if needed
  let normalizedFile: File | undefined;
  if (normalizedMimeType !== file.type) {
    normalizedFile = new File([file], file.name, { 
      type: normalizedMimeType,
      lastModified: file.lastModified
    });
  }

  return { 
    valid: true,
    normalizedFile
  };
}

export async function validateVideoDuration(file: File): Promise<ValidationResult> {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    
    // Important for iOS
    video.playsInline = true;
    
    // Set up timeout in case metadata loading hangs
    const timeoutId = setTimeout(() => {
      URL.revokeObjectURL(video.src);
      resolve({ valid: true }); // Assume valid on timeout for iOS
    }, 5000);

    video.onloadedmetadata = () => {
      clearTimeout(timeoutId);
      URL.revokeObjectURL(video.src);
      
      if (video.duration > MAX_VIDEO_DURATION) {
        // Check user's language for error message
        const userLang = navigator.language;
        const errorMessage = userLang.startsWith('pt') 
          ? `O vídeo deve ter no máximo ${MAX_VIDEO_DURATION} segundos`
          : `Video must be ${MAX_VIDEO_DURATION} seconds or less`;
          
        resolve({ valid: false, error: errorMessage });
      } else {
        resolve({ valid: true });
      }
    };

    video.onerror = () => {
      clearTimeout(timeoutId);
      URL.revokeObjectURL(video.src);
      
      // For iOS, be more permissive with errors
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        console.warn('iOS video validation error - assuming valid');
        resolve({ valid: true });
      } else {
        // Check user's language for error message
        const userLang = navigator.language;
        const errorMessage = userLang.startsWith('pt') 
          ? 'Falha ao validar vídeo'
          : 'Failed to validate video';
          
        resolve({ valid: false, error: errorMessage });
      }
    };

    video.src = URL.createObjectURL(file);
    
    // Force load for iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      video.load();
    }
  });
}