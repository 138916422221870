/**
 * Gets the user's IP address using a public API
 * @returns The user's IP address
 */
export async function getUserIP(): Promise<string> {
  try {
    // Use ipify API to get the user's IP address
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error getting IP address:', error);
    return 'unknown';
  }
}