import { useState } from 'react';
import { X, LogOut, Loader2 } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { deleteUserAccount } from '../utils/account';
import { logUserAction, ActionType } from '../utils/logging';
import toast from 'react-hot-toast';

interface DeleteAccountDialogProps {
  onClose: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Excluir Conta',
    warning: {
      title: 'Atenção: Esta ação é permanente',
      items: [
        'Sua conta será permanentemente excluída',
        'Todas as suas publicações e stories serão removidos',
        'Seu perfil e dados não poderão ser recuperados',
        'Você perderá todas as suas conexões e conteúdo'
      ],
      completion: 'Aguarde a finalização completa do processo. Não feche a janela antes de finalizar.'
    },
    buttons: {
      cancel: 'Cancelar',
      delete: 'Excluir Minha Conta',
      deleting: 'Excluindo...'
    },
    confirmDialog: 'Tem certeza que deseja excluir sua conta? Esta ação não pode ser desfeita.',
    success: 'Sua conta foi excluída',
    error: 'Falha ao excluir conta'
  },
  'en': {
    title: 'Delete Account',
    warning: {
      title: 'Warning: This action is permanent',
      items: [
        'Your account will be permanently deleted',
        'All your posts and stories will be removed',
        'Your profile and data cannot be recovered',
        'You will lose all your connections and content'
      ],
      completion: 'Please wait for the complete deletion process. Do not close the window before completion.'
    },
    buttons: {
      cancel: 'Cancel',
      delete: 'Delete My Account',
      deleting: 'Deleting...'
    },
    confirmDialog: 'Are you sure you want to delete your account? This action cannot be undone.',
    success: 'Your account has been deleted',
    error: 'Failed to delete account'
  }
};

export function DeleteAccountDialog({ onClose, isDarkMode = false, language = 'en' }: DeleteAccountDialogProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { user } = useAuth();

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleDeleteAccount = async () => {
    if (!user || isDeleting) return;

    const confirmed = window.confirm(t('confirmDialog'));
    if (!confirmed) return;

    try {
      setIsDeleting(true);
      
      // Log the account deletion action
      await logUserAction(
        user.uid,
        user.displayName?.replace('@', '') || '',
        user.email || '',
        ActionType.ADMIN_ACTION,
        {
          action: 'delete_account',
          timestamp: new Date().toISOString()
        }
      );
      
      await deleteUserAccount(user);
      toast.success(t('success'));
    } catch (error: any) {
      console.error('Error deleting account:', error);
      toast.error(error.message || t('error'));
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-red-600">{t('title')}</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div className="flex items-start space-x-3 bg-red-50 p-4 rounded-lg">
            <LogOut className="h-6 w-6 text-red-600 flex-shrink-0 mt-0.5" />
            <div className="text-sm text-red-600">
              <p className="font-medium mb-1">{t('warning.title')}</p>
              <ul className="list-disc pl-4 space-y-1">
                {t('warning.items').map((item: string, index: number) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <p className="mt-3 font-medium text-red-700 bg-red-100 p-2 rounded">
                {t('warning.completion')}
              </p>
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
            >
              {t('buttons.cancel')}
            </button>
            <button
              onClick={handleDeleteAccount}
              disabled={isDeleting}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50 flex items-center space-x-2"
            >
              {isDeleting ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>{t('buttons.deleting')}</span>
                </>
              ) : (
                <span>{t('buttons.delete')}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}