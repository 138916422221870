// Video size limits
export const MAX_VIDEO_SIZE = 500 * 1024 * 1024; // 500MB
export const MAX_VIDEO_DURATION = 30; // 30 seconds

// Video dimension constraints
export const VIDEO_DIMENSIONS = {
  MAX_RESOLUTION: 2080,
  ASPECT_RATIO: 1, // 1:1 for square videos
  CONSTRAINTS: {
    width: { min: 640, ideal: 1920, max: 2080 },
    height: { min: 640, ideal: 1920, max: 2080 }
  }
};

// Video processing settings
export const VIDEO_BITRATE = 2500000; // 2.5 Mbps
export const AUDIO_BITRATE = 128000; // 128 kbps
export const TARGET_VIDEO_SIZE = 100 * 1024 * 1024; // 100MB target size