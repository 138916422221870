import { useState, useEffect, useRef } from 'react';
import { Camera, Music, Loader2, Image, Video } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { analyticsEvents } from '../lib/firebase';
import { validateStoryFile, validateVideoDuration } from '../utils/stories/validation';
import { uploadStory } from '../utils/stories/upload';
import { SpotifySearch } from './SpotifySearch';
import { YoutubeSearch } from './YoutubeSearch';
import { moderateImage } from '../utils/vision';
import { moderateVideo } from '../utils/video/moderation';
import toast from 'react-hot-toast';

interface CreateStoryProps {
  onStoryCreated: (story: any) => void;
  isDarkMode?: boolean;
  language?: string;
  translations?: any;
}

const STORY_COOLDOWN = 60 * 60 * 1000; // 1 hour in milliseconds

export function CreateStory({ onStoryCreated, isDarkMode = false, language = 'en', translations }: CreateStoryProps) {
  const [uploading, setUploading] = useState(false);
  const [showSpotifySearch, setShowSpotifySearch] = useState(false);
  const [showYoutubeSearch, setShowYoutubeSearch] = useState(false);
  const [lastStoryTime, setLastStoryTime] = useState<Date | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [moderating, setModerating] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoStatus, setVideoStatus] = useState<'idle' | 'uploading' | 'processing' | 'moderating' | 'approved' | 'error'>('idle');
  const { user } = useAuth();
  const photoInputRef = useRef<HTMLInputElement>(null);
  const galleryInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    const lastPost = localStorage.getItem('lastStoryTime');
    if (lastPost) {
      setLastStoryTime(new Date(lastPost));
    }
  }, []);

  useEffect(() => {
    if (!lastStoryTime) return;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const lastPost = lastStoryTime.getTime();
      const remaining = Math.max(0, STORY_COOLDOWN - (now - lastPost));
      setCooldownRemaining(remaining);

      if (remaining === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastStoryTime]);

  const resetFileInputs = () => {
    if (photoInputRef.current) photoInputRef.current.value = '';
    if (galleryInputRef.current) galleryInputRef.current.value = '';
    if (videoInputRef.current) videoInputRef.current.value = '';
    setUploadProgress(0);
    setVideoStatus('idle');
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'video') => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    if (cooldownRemaining > 0) {
      const minutesLeft = Math.ceil(cooldownRemaining / 60000);
      toast.error(`Please wait ${minutesLeft} minutes before posting another story`);
      resetFileInputs();
      return;
    }

    try {
      setUploadProgress(0);
      
      // Validate file
      const validation = validateStoryFile(file, type);
      if (!validation.valid) {
        toast.error(validation.error);
        resetFileInputs();
        return;
      }
      
      if (type === 'image') {
        setModerating(true);
        setUploadProgress(10);
        
        // Convert file to base64 for Cloud Vision
        const base64Image = await new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(file);
        });

        setUploadProgress(30);
        // Check image with Cloud Vision
        const isImageSafe = await moderateImage(base64Image);
        if (!isImageSafe) {
          toast.error('This image contains promotional content or inappropriate material. Please choose another image.');
          resetFileInputs();
          return;
        }
        
        setUploadProgress(60);
      } else if (type === 'video') {
        // Set status to processing
        setVideoStatus('processing');
        setUploadProgress(10);
        
        // Validate video duration
        const durationValidation = await validateVideoDuration(file);
        if (!durationValidation.valid) {
          toast.error(durationValidation.error || 'Video must be 30 seconds or less');
          resetFileInputs();
          return;
        }
        
        // Moderate video content
        setVideoStatus('moderating');
        setUploadProgress(30);
        const isVideoSafe = await moderateVideo(file);
        
        if (!isVideoSafe) {
          toast.error('This video contains inappropriate content. Please choose another video.');
          resetFileInputs();
          return;
        }
        
        setVideoStatus('approved');
        setUploadProgress(60);
      }

      setUploading(true);
      const loadingToast = toast.loading('Creating your story...');

      // Upload with progress tracking
      const updateProgress = (progress: number) => {
        setUploadProgress(60 + Math.floor(progress * 0.4)); // Scale from 60-100%
      };

      const story = await uploadStory({
        file,
        type,
        userId: user.uid,
        userName: user.displayName || '',
        userPhotoURL: user.photoURL,
        onProgress: updateProgress
      });

      onStoryCreated(story);
      analyticsEvents.createStory();

      const now = new Date();
      setLastStoryTime(now);
      localStorage.setItem('lastStoryTime', now.toISOString());
      
      toast.dismiss(loadingToast);
      toast.success('Story created successfully!');
      resetFileInputs();
    } catch (error: any) {
      console.error('Error processing story:', error);
      toast.error(error.message || 'Failed to create story');
      resetFileInputs();
      setVideoStatus('error');
    } finally {
      setUploading(false);
      setModerating(false);
      setUploadProgress(0);
    }
  };

  const handleSpotifyTrackSelect = async (track: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  }) => {
    if (!user) return;

    if (cooldownRemaining > 0) {
      const minutesLeft = Math.ceil(cooldownRemaining / 60000);
      toast.error(`Please wait ${minutesLeft} minutes before posting another story`);
      return;
    }

    try {
      setUploading(true);
      const loadingToast = toast.loading('Creating your story...');

      const story = await uploadStory({
        type: 'spotify',
        userId: user.uid,
        userName: user.displayName || '',
        userPhotoURL: user.photoURL,
        spotifyTrack: track
      });

      onStoryCreated(story);
      analyticsEvents.createStory();

      const now = new Date();
      setLastStoryTime(now);
      localStorage.setItem('lastStoryTime', now.toISOString());
      
      toast.dismiss(loadingToast);
      toast.success('Story created successfully!');
      setShowSpotifySearch(false);
    } catch (error: any) {
      console.error('Error creating story:', error);
      toast.error(error.message || 'Failed to create story');
    } finally {
      setUploading(false);
    }
  };

  const handleYoutubeVideoSelect = async (video: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  }) => {
    if (!user) return;

    if (cooldownRemaining > 0) {
      const minutesLeft = Math.ceil(cooldownRemaining / 60000);
      toast.error(`Please wait ${minutesLeft} minutes before posting another story`);
      return;
    }

    try {
      setUploading(true);
      const loadingToast = toast.loading('Creating your story...');

      const story = await uploadStory({
        type: 'youtube',
        userId: user.uid,
        userName: user.displayName || '',
        userPhotoURL: user.photoURL,
        youtubeVideo: video
      });

      onStoryCreated(story);
      analyticsEvents.createStory();

      const now = new Date();
      setLastStoryTime(now);
      localStorage.setItem('lastStoryTime', now.toISOString());
      
      toast.dismiss(loadingToast);
      toast.success('Story created successfully!');
      setShowYoutubeSearch(false);
    } catch (error: any) {
      console.error('Error creating story:', error);
      toast.error(error.message || 'Failed to create story');
    } finally {
      setUploading(false);
    }
  };

  const t = (key: string, params?: Record<string, string>) => {
    if (!translations) return key;

    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-2">
        <button
          onClick={() => photoInputRef.current?.click()}
          disabled={uploading || moderating}
          className={`flex items-center space-x-1 px-2 py-1 text-xs rounded-full disabled:opacity-50 ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-black text-white hover:bg-gray-900'
          }`}
        >
          <Camera className="h-3.5 w-3.5" />
          <span>Câmera</span>
        </button>

        <button
          onClick={() => galleryInputRef.current?.click()}
          disabled={uploading || moderating}
          className={`flex items-center space-x-1 px-2 py-1 text-xs rounded-full disabled:opacity-50 ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-black text-white hover:bg-gray-900'
          }`}
        >
          <Image className="h-3.5 w-3.5" />
          <span>Foto</span>
        </button>

        <button
          onClick={() => videoInputRef.current?.click()}
          disabled={uploading || moderating}
          className={`flex items-center space-x-1 px-2 py-1 text-xs rounded-full disabled:opacity-50 ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-black text-white hover:bg-gray-900'
          }`}
        >
          <Video className="h-3.5 w-3.5" />
          <span>Vídeo</span>
        </button>

        <button
          onClick={() => setShowSpotifySearch(true)}
          disabled={uploading || moderating}
          className="flex items-center space-x-1 px-2 py-1 bg-green-500 text-white text-xs rounded-full hover:bg-green-600 disabled:opacity-50"
        >
          <Music className="h-3.5 w-3.5" />
          <span>Música</span>
        </button>

        <button
          onClick={() => setShowYoutubeSearch(true)}
          disabled={uploading || moderating}
          className="flex items-center space-x-1 px-2 py-1 bg-red-500 text-white text-xs rounded-full hover:bg-red-600 disabled:opacity-50"
        >
          <Video className="h-3.5 w-3.5" />
          <span>Vídeo</span>
        </button>

        <input
          ref={photoInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => handleFileSelect(e, 'image')}
          className="hidden"
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />

        <input
          ref={galleryInputRef}
          type="file"
          accept="image/*"
          onChange={(e) => handleFileSelect(e, 'image')}
          className="hidden"
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />

        <input
          ref={videoInputRef}
          type="file"
          accept="video/*"
          onChange={(e) => handleFileSelect(e, 'video')}
          className="hidden"
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />
      </div>

      {cooldownRemaining > 0 && (
        <div className={`text-sm text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          {t('createStory.waitMessage', { time: Math.ceil(cooldownRemaining / 60000).toString() })}
        </div>
      )}

      {(uploading || moderating || videoStatus !== 'idle') && (
        <div className="flex flex-col items-center space-y-2">
          <div className="w-full max-w-xs bg-gray-800 rounded-lg p-4">
            <div className="mb-2 flex justify-between items-center">
              <span className="text-sm font-medium text-white">
                {videoStatus === 'processing' && (language === 'pt-BR' ? 'Processando vídeo...' : 'Processing video...')}
                {videoStatus === 'moderating' && (language === 'pt-BR' ? 'Verificando conteúdo do vídeo...' : 'Checking video content...')}
                {videoStatus === 'uploading' && (language === 'pt-BR' ? 'Enviando vídeo...' : 'Uploading video...')}
                {videoStatus === 'approved' && (language === 'pt-BR' ? 'Vídeo aprovado! Pronto para publicar.' : 'Video approved! Ready to publish.')}
                {videoStatus === 'error' && (language === 'pt-BR' ? 'Erro ao processar vídeo' : 'Error processing video')}
                {videoStatus === 'idle' && (moderating ? (language === 'pt-BR' ? 'Verificando conteúdo...' : 'Checking content...') : (language === 'pt-BR' ? 'Processando...' : 'Processing...'))}
              </span>
              <span className="text-sm text-white">{uploadProgress}%</span>
            </div>
            <div className="w-full bg-gray-700 rounded-full h-2">
              <div 
                className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
          </div>
        </div>
      )}

      {showSpotifySearch && (
        <SpotifySearch
          onSelect={handleSpotifyTrackSelect}
          onClose={() => setShowSpotifySearch(false)}
          isDarkMode={isDarkMode}
        />
      )}

      {showYoutubeSearch && (
        <YoutubeSearch
          onSelect={handleYoutubeVideoSelect}
          onClose={() => setShowYoutubeSearch(false)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
}