import axios from 'axios';

interface GeoLocation {
  country: string;
  region: string;
  city: string;
  language: string;
}

const GOOGLE_API_KEY = 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8';

export async function detectUserLocation(): Promise<GeoLocation> {
  try {
    // First try browser geolocation as it's more accurate
    if (navigator.geolocation) {
      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          });
        });

        // Use Google's Geocoding API to get location details from coordinates
        const geocodingResponse = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${GOOGLE_API_KEY}`,
          { timeout: 5000 }
        );
        
        if (geocodingResponse.data.status === 'OK') {
          const results = geocodingResponse.data.results;
          
          // Extract country code
          let countryCode = 'US'; // Default
          let region = '';
          let city = '';
          
          for (const result of results) {
            for (const component of result.address_components) {
              if (component.types.includes('country')) {
                countryCode = component.short_name;
              }
              if (component.types.includes('administrative_area_level_1')) {
                region = component.long_name;
              }
              if (component.types.includes('locality')) {
                city = component.long_name;
              }
            }
          }
          
          return {
            country: countryCode,
            region: region,
            city: city,
            language: countryCode === 'BR' ? 'pt-BR' : 'en'
          };
        }
      } catch (error) {
        console.warn('Browser geolocation failed:', error);
        // Fall through to IP-based geolocation
      }
    }

    // Fallback to Google's Geolocation API (IP-based)
    try {
      const geolocationResponse = await axios.post(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`,
        {}, // Empty body for IP-based location
        { timeout: 5000 }
      );
      
      if (geolocationResponse.data && geolocationResponse.data.location) {
        const { lat, lng } = geolocationResponse.data.location;
        
        // Use Google's Geocoding API to get location details from coordinates
        const geocodingResponse = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`,
          { timeout: 5000 }
        );
        
        if (geocodingResponse.data.status === 'OK') {
          const results = geocodingResponse.data.results;
          
          // Extract country code
          let countryCode = 'US'; // Default
          let region = '';
          let city = '';
          
          for (const result of results) {
            for (const component of result.address_components) {
              if (component.types.includes('country')) {
                countryCode = component.short_name;
              }
              if (component.types.includes('administrative_area_level_1')) {
                region = component.long_name;
              }
              if (component.types.includes('locality')) {
                city = component.long_name;
              }
            }
          }
          
          return {
            country: countryCode,
            region: region,
            city: city,
            language: countryCode === 'BR' ? 'pt-BR' : 'en'
          };
        }
      }
    } catch (error) {
      console.warn('Google Geolocation API failed:', error);
    }

    // Default fallback
    return {
      country: 'US',
      region: '',
      city: '',
      language: 'en'
    };
  } catch (error) {
    console.warn('Error detecting location:', error);
    // Graceful fallback to US/English
    return {
      country: 'US',
      region: '',
      city: '',
      language: 'en'
    };
  }
}